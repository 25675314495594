import React, {Component} from 'react'
import * as marked from 'marked'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import moment from 'moment'
import {GuidelineLayout} from '../../components/layouts'
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import { withGuidelineContext } from '../../contexts/index'
import richtextFormatConfig from '../../utils/richtext'
import { withPreview } from '../../components/common'

class GuidelinePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sitemap: []
        }
    }

    componentDidMount() {
        this.props.getSitemap().then(sitemap => {
            this.setState({
                sitemap
            })
        })
        this.props.getEntry('guideline', this.props.location.search).then((entry) => {
            this.initializeGuideline(entry)
        })
    }

    initializeGuideline(entry) {
        const guideline = {}

        guideline.documentType = !!guideline.guidelineType ? guideline.guidelineType.guidelineType : ''
        guideline.publishDate = moment(new Date())

        // constant
        guideline.title = entry.fields.title
        guideline.htmlSummary = marked(entry.fields.summary)

        // model
        guideline.serviceProvider = entry.fields.serviceProvider ? entry.fields.serviceProvider.fields : {}
        guideline.guidelineType = { guidelineType: entry.fields.guidelineType }
        guideline.editor = entry.fields.editor ? entry.fields.editor.fields : {}
        guideline.owner = entry.fields.owner ? entry.fields.owner.fields : {}

        // array
        const arrayFields = ['authors', 'categories', 'forms', 'healthPathways', 'informationForFamilies', 'relatedInformation', 'externalTables']
        forEach(arrayFields, (field) => {
            guideline[field] = map(entry.fields[field], (value) => {
                return value.fields
            })
        })

        // richtext
        guideline.richtext = {
            childContentfulRichText: {
                html: documentToHtmlString(entry.fields.richtext, richtextFormatConfig)
            }
        }

         // references
        guideline.references = {
            childContentfulRichText: {
                html: documentToHtmlString(entry.fields.references, richtextFormatConfig)
            }
        }

        guideline.disclaimer = {
            content: {
                childContentfulRichText: {
                    html: documentToHtmlString(entry.fields.disclaimer, richtextFormatConfig)
                }
            }
        }

        this.props.guidelineContext.setGuideline(guideline)
    }

    render() {
        const { location } = this.props

        return !this.props.loading ? (<GuidelineLayout preview={true} location={location} sitemap={this.state.sitemap}/>) : <></>
    }
}

export default withGuidelineContext(withPreview(GuidelinePreview))